<template>
  <div class="page-container voucher-list">
    <div class="page-title-container">
      <h1 class="page-title">Recibos</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="d-flex align-items-center justify-content-between border-0 filter"
              @click="showFilters = !showFilters"
            >
              <FiltersIcon/>
              <div>Filtrar recibos</div>
            </button>

            <div class="d-md-none">
              <button
                type="button"
                class="button-tooltip m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <RefreshTableIcon/>
              </button>

              <button
                type="button"
                class="button-tooltip"
                v-b-tooltip.hover
                title="Descargar"
                @click="downloadList()"
              >
                <DownloadTableIcon/>
              </button>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center mt-2 mt-md-0">
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <RefreshTableIcon/>
              </button>

              <button
                type="button"
                class="button-tooltip"
                v-b-tooltip.hover
                title="Descargar"
                @click="downloadList()"
              >
                <DownloadTableIcon/>
              </button>
            </div>

            <SearchBar
              placeholder="Buscar por número de recibo, concepto o código de pago"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item"/>
            </button>
          </span>
        </div>
      </div>
      <div>
        <!-- Mobile -->
        <table class="custom-table position-relative w-100 d-xl-none">
          <thead>
          <tr>
            <th class="text-left px-3">N° de recibo y concepto</th>
          </tr>
          </thead>
          <tbody v-if="list.length">
          <tr v-for="o in list" :key="o.id">
            <td class="px-3">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <p class="my-0">
                    <span>{{ o.code || "-" }}</span>
                    <span class="d-block">{{ o.concept || "-" }}</span>
                  </p>

                  <div
                    class="table-mobile-options mt-2"
                    v-if="showTableOptions"
                  >
                    <div>
                      <a
                        href="#"
                        class="mr-2"
                        @click="openDownloadVoucherModal(o)"
                        v-if="showDownloadVoucherOption(o)"
                      >
                        Descargar recibo
                      </a>
                    </div>

                    <a
                      href="#"
                      class="mr-2"
                      @click="openSendVoucherByEmailModal(o)"
                      v-if="showSendVoucherOption(o)"
                    >
                      Enviar por email
                    </a>

                    <a
                      href="#"
                      class="mt-2 mr-2"
                      v-if="showMarkAsPaidOption(o)"
                    >
                      Marcar como pagado
                    </a>
                  </div>
                </div>

                <button
                  type="button"
                  class="border-0 bg-transparent"
                  @click="o.showMobileData = !o.showMobileData"
                >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                  <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                </button>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Código de pago:</span>
                    <span>{{
                        o.paymentCode || "-"
                      }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Concepto:</span>
                    <span>{{
                        o.concept || "-"
                      }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">F. de emisión:</span>
                    <span v-localDate="o.uploadDate" v-if="o.uploadDate"></span>
                    <span v-else>-</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">F. de vencimiento:</span>
                    <span v-localDate="o.dueDate" v-if="o.dueDate"></span>
                    <span v-else>-</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Importe:</span>
                    <span>{{
                        o.formattedAmount || "-"
                      }}</span>
                  </li>
                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Penalidades</span>
                    <span>{{
                        o.formattedLateFeeAmount || "-"
                      }}</span>
                  </li>

                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Importe a pagar</span>
                    <span>{{
                        o.formattedToPayAmount || "-"
                      }}</span>
                  </li>

                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Imp. deuda vencida</span>
                    <span>{{
                        o.formattedOverdueDebtAmount || "-"
                      }}</span>
                  </li>

                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">F. de pago</span>
                    <span v-localDate="o.paidAt" v-if="o.paidAt"></span>
                    <span v-else>-</span>
                  </li>

                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Importe pagado</span>
                    <span>{{
                        o.formattedPayedAmount || "-"
                      }}</span>
                  </li>

                  <li class="mb-1">
                    <span class="font-weight-bold d-inline-block mr-1">Estado</span>
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                        getStatus(o.status).label
                      }}</span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <p class="empty-table-text text-center" v-else>
            No se encontraron registros.
          </p>
        </table>

        <!-- Desktop -->
        <table class="custom-table w-100 position-relative d-none d-xl-table">
          <thead>
          <tr>
            <th>N° de recibo</th>
            <th>
              Código de <br/>
              pago
            </th>
            <th class="text-left pl-4">Concepto</th>
            <th>
              F. de <br/>
              emisión
            </th>
            <th>
              F. de <br/>
              vencimiento
            </th>
            <th>Importe</th>
            <th>
              Penalidades
            </th>
            <th>
              Importe <br/>
              a pagar
            </th>
            <th>
              Imp. deuda <br/>
              vencida
            </th>
            <th>
              Fecha <br/>
              de pago
            </th>
            <th>
              Importe <br/>
              pagado
            </th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
          </thead>
          <tbody v-if="list.length">
          <tr v-for="o in list" :key="o.id">
            <td class="text-center">{{ o.code || "-" }}</td>
            <td class="text-center">{{ o.paymentCode || "-" }}</td>
            <td class="pl-4">{{ o.concept || "-" }}</td>
            <td class="text-center">
              <span v-localDate="o.uploadDate" v-if="o.uploadDate"></span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              <span v-localDate="o.dueDate" v-if="o.dueDate"></span>
              <span v-else>-</span>
            </td>
            <td class="text-center text-uppercase">
              {{ o.formattedAmount || "-" }}
            </td>
            <td class="text-center">
              {{ o.formattedLateFeeAmount || "-" }}
            </td>
            <td class="text-center">
              {{ o.formattedToPayAmount || "-" }}
            </td>
            <td class="text-center">
              {{ o.formattedOverdueDebtAmount || "-" }}
            </td>
            <td class="text-center">
              <span v-localDate="o.paidAt" v-if="o.paidAt"></span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              {{ o.formattedPayedAmount || "-" }}
            </td>
            <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                    getStatus(o.status).label
                  }}</span>
            </td>

            <td class="text-center d_option">
              <DropdownTable v-if="showTableOptions(o)">
                <template slot="dropdown-content">
                  <div>
                    <DropdownItem
                      text="Descargar recibo"
                      @click="downloadVoucher(o)"
                      v-if="showDownloadVoucherOption(o)"
                    />
                  </div>

                  <DropdownItem
                    text="Enviar por email"
                    @click="openSendVoucherByEmailModal(o)"
                    v-if="showSendVoucherOption(o)"
                  />

                  <DropdownItem
                    text="Marcar como pagado"
                    @click="openMarkAsPaidModal(o)"
                    v-if="showMarkAsPaidOption(o)"
                  />
                </template>
              </DropdownTable>

              <span v-else>-</span>
            </td>
          </tr>
          </tbody>

          <p
            class="font-weight-normal empty-table-text text-center pt-2"
            v-else
          >
            No se encontraron registros.
          </p>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />

    <custom-modal
      :show="markAsPaidModal.show"
      @toAction="markAsPaidModalResult"
      title="Marcar como pagado"
    >
      <template slot="modal-content">
        <simple-alert
          text="Asegurese de marcar la opción correcta porque este proceso no es reversible."
          type="light-gray"
        >
          <template slot="icon">
            <WarningAlertIcon/>
          </template>
        </simple-alert>

        <form @submit.prevent="updateStatus()" class="custom-form mt-4">
          <div class="form-group d-flex align-items-start">
            <div class="modal-label-container">
              <label for="paymentMethod" class="modal-label m-0"
              >Medio de pago</label
              >
            </div>

            <div class="position-relative flex-1">
              <FormSelect
                id="paymentMethod"
                v-model="markAsPaidModal.form.paymentMethod"
                :items="markAsPaidModal.paymentTypeList"
                defaultOption="Elegir medio de pago"
                :showError="$v.markAsPaidModal.form.paymentMethod.$error"
              />
              <form-error
                message="Medio de pago es requerido"
                class="form-error"
                v-if="
                  $v.markAsPaidModal.form.paymentMethod.$error &&
                  !$v.markAsPaidModal.form.paymentMethod.required
                "
              ></form-error>
            </div>
          </div>

          <div class="form-group mb-0 d-flex align-items-start">
            <div class="modal-label-container">
              <label for="payedAmount" class="modal-label m-0"
              >Importe pagado</label
              >
            </div>

            <div class="position-relative flex-1">
              <FormInput
                id="payedAmount"
                type="number"
                min="0"
                placeholder="Ingresar importe pagado"
                :show-error="
                  $v.markAsPaidModal.form.payedAmount.$error &&
                  !$v.markAsPaidModal.form.payedAmount.required
                "
                v-model="markAsPaidModal.form.payedAmount"
              />

              <form-error
                message="Importe pagado es requerido"
                class="form-error"
                v-if="
                  $v.markAsPaidModal.form.payedAmount.$error &&
                  !$v.markAsPaidModal.form.payedAmount.required
                "
              ></form-error>
            </div>
          </div>
        </form>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";

import {Alert, CloseBorderRoundedIcon, ConfirmationModal, ConfirmationModalMixin, Constants, CustomModal, DownloadTableIcon, DropdownItem, DropdownTable, FilterMenu, FilterMixin, FiltersIcon, FormError, FormInput, FormSelect, PaginationMixin, RefreshTableIcon, SearchBar, SimpleAlert, Util, WarningAlertIcon} from "wize-admin";

import {required} from "vuelidate/lib/validators";

import {Constants as LocalConstants} from "@/core/utils";

import {PaymentCodeService, VoucherService} from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
    DownloadTableIcon,
    ConfirmationModal,
    CustomModal,
    SimpleAlert,
    WarningAlertIcon,
    FormInput,
    FormSelect,
    FormError,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.VoucherStatus.PENDING,
            LocalConstants.VoucherStatus.PAID,
          ],
        },
        {
          label: "Códigos de pago",
          key: "paymentCodes",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Fecha de emisión",
          key: "uploadDate",
          type: Constants.TypeFilters.DATE,
        },
        {
          label: "Fecha de vencimiento",
          key: "dueDate",
          type: Constants.TypeFilters.DATE,
        },
        {
          label: "Fecha de pago",
          key: "paidAt",
          type: Constants.TypeFilters.DATE,
        },
      ],
      markAsPaidModal: {
        show: false,
        form: {
          paymentMethod: null,
          payedAmount: null,
        },
        paymentTypeList: [
          LocalConstants.PaymentMethods.WIRE_TRANSFER,
          LocalConstants.PaymentMethods.AUTOMATIC_DEBITS,
        ],
      },
    };
  },
  validations: {
    markAsPaidModal: {
      form: {
        paymentMethod: {required},
        payedAmount: {required},
      },
    },
  },
  methods: {
    async initialize() {
      const filterOptions = this.filterOptions.find(
        (item) => item.key === "paymentCodes"
      );
      const response = await PaymentCodeService.listAll()
      filterOptions.options = response.payload.map((item) => {
        return {
          key: item.code,
          label: item.code,
        };
      });
    },
    setFilters(options) {
      this.showFilters = false;
      this.filters = {...options};
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const response = await VoucherService.list(this.getFilterParams());
        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.VoucherStatus);
    },
    showMarkAsPaidOption(o) {
      const {VoucherStatus} = LocalConstants;

      return o.status == VoucherStatus.PENDING.key;
    },
    showDownloadVoucherOption(o) {
      const {VoucherProcessStatus} = LocalConstants;
      const {GENERATED, SENDING, SENT} = VoucherProcessStatus;

      let list = [GENERATED.key, SENDING.key, SENT.key];

      return list.includes(o.processStatus);
    },
    showSendVoucherOption(o) {
      const {VoucherProcessStatus} = LocalConstants;
      const {GENERATED, SENDING, SENT} = VoucherProcessStatus;

      let list = [GENERATED.key, SENDING.key, SENT.key];

      return list.includes(o.processStatus);
    },
    showTableOptions(o) {
      const {VoucherProcessStatus, VoucherStatus} = LocalConstants;
      const {GENERATED, SENDING, SENT} = VoucherProcessStatus;

      let list = [GENERATED.key, SENDING.key, SENT.key];

      return (
        o.status == VoucherStatus.PENDING.key ||
        (o.status == VoucherStatus.PAID.key && list.includes(o.processStatus))
      );
    },
    openSendVoucherByEmailModal(o) {
      this.selectedOption = o;

      this.openConfirmationModal({
        title: "Enviar por email",
        description: "¿Está seguro de enviar por email?",
        acceptText: "Aceptar",
        action: "send",
      });
    },
    markAsPaidModalResult(value) {
      if (value === "cancel") {
        this.selectedOption = null;
        this.markAsPaidModal.show = false;
        this.clearMarkAsPaidModal();
      } else {
        this.markAsPaidVoucher();
      }
    },
    async markAsPaidVoucher() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.$store.dispatch("app/loading", true);

      try {
        let data = {...this.markAsPaidModal.form};

        let response = await VoucherService.markAsPaid(
          this.selectedOption.id,
          data
        );

        if (response.status === "OK") {
          Alert.success("Se actualizó la información correctamente");
          this.loadData();

          this.selectedOption = null;
          this.markAsPaidModal.show = false;
        } else {
          Alert.error("Ocurrió un error, inténtelo nuevamente.");
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
        this.clearMarkAsPaidModal();
      }
    },
    clearMarkAsPaidModal() {
      this.markAsPaidModal.form.paymentMethod = null;
      this.markAsPaidModal.form.payedAmount = null;
      this.$v.$reset();
    },
    openMarkAsPaidModal(o) {
      this.selectedOption = o;
      this.markAsPaidModal.show = true;
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "send") this.sendVoucher();
    },
    async downloadVoucher(voucher) {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await VoucherService.downloadVoucher(voucher.id);
        fileDownload(response.data, Util.getFileName(response));
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadList() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await VoucherService.downloadVoucherList(this.getFilterParams());
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getFilterParams(){
      const params = {
        page: this.page - 1,
        size: this.pageSize,
        search: this.search,
        ...this.filters,
      };

      if (params.uploadDate) {
        params.uploadDateStart = moment(params.uploadDate.startDate).format(
          "YYYY-MM-DD"
        );
        params.uploadDateEnd = moment(params.uploadDate.endDate).format(
          "YYYY-MM-DD"
        );
      }

      if (params.dueDate) {
        params.dueDateStart = moment(params.dueDate.startDate).format(
          "YYYY-MM-DD"
        );
        params.dueDateEnd = moment(params.dueDate.endDate).format(
          "YYYY-MM-DD"
        );
      }

      if (params.paidAt) {
        params.paidAtStart = moment(params.paidAt.startDate).format(
          "YYYY-MM-DD"
        );
        params.paidAtEnd = moment(params.paidAt.endDate).format(
          "YYYY-MM-DD"
        );
      }
      return params;
    },
    async sendVoucher() {
      this.$store.dispatch("app/loading", true);

      try {
        let response = await VoucherService.sendVoucher(this.selectedOption.id);

        if (response.status === "OK") {
          Alert.success("Se envió la información correctamente");
          this.loadData();

          this.selectedOption = null;
          this.markAsPaidModal.show = false;
        } else {
          Alert.error("Ocurrió un error, inténtelo nuevamente.");
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.initialize();
    await this.loadData();
  },
};
</script>

<style lang="stylus">
@import "../Styles/voucher-list.styl"
</style>
