<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Lotes</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="d-flex align-items-center justify-content-between border-0 filter"
              @click="showFilters = !showFilters"
            >
              <FiltersIcon />
              <div>Filtrar lotes</div>
            </button>

            <div>
              <button
                type="button"
                class="button-tooltip d-md-none"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <RefreshTableIcon />
              </button>

              <b-button
                :to="{ name: 'config-lots-create' }"
                variant="none"
                class="button button-primary d-md-none"
                >Agregar</b-button
              >
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section class="w-100 d-flex align-items-center mt-2 mt-md-0">
            <button
              type="button"
              class="button-tooltip d-none d-md-block"
              v-b-tooltip.hover
              title="Recargar"
              @click="loadData()"
            >
              <RefreshTableIcon />
            </button>

            <SearchBar
              placeholder="Buscar por código de pago o lote"
              @search="onSearch"
              @input="search = $event"
              class="w-100"
            />

            <div class="d-none d-md-block">
              <b-button
                :to="{ name: 'config-lots-create' }"
                variant="none"
                class="button button-primary ml-md-2"
                >Agregar</b-button
              >
            </div>
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div>
        <!-- Mobile -->
        <table class="custom-table position-relative w-100 d-xl-none">
          <thead>
            <tr>
              <th class="text-left px-4">
                Lote
                <span class="d-inline-block ml-3">Código de pago</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="o in list" :key="o.id">
              <td class="px-4">
                <div class="d-flex justify-content-between align-items-start">
                  <div>
                    <p class="my-0">
                      <span>{{ o.number || '-' }}</span>
                      <span class="d-inline-block ml-3">{{ o.code   }}</span>
                    </p>

                    <div class="table-mobile-options mt-2">
                      <router-link
                        :to="{
                          name: 'config-lots-details',
                          params: { id: o.id },
                        }"
                        class="mr-2"
                      >
                        Ver datos
                      </router-link>
                      <router-link
                        :to="{ name: 'config-lots-edit', params: { id: o.id } }"
                        class="mr-2"
                      >
                        Editar
                      </router-link>
                      <a
                        href="#"
                        class="text-danger"
                        @click="openDelete(o)"
                      >
                        Eliminar
                      </a>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>

                <div class="pt-3" v-if="o.showMobileData">
                  <ul class="list-unstyled p-0">
                    <li class="mb-1">
                      <span class="font-weight-bold">Tipo:</span>
                      <span class="d-inline-block ml-1">{{
                        getType(o.type).label
                      }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Metraje:</span>
                      <span class="d-inline-block ml-1">{{ o.area || '-' }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Porcentaje:</span>
                      <span class="d-inline-block ml-1">{{
                        o.percentage || '-'
                      }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold">Dirección fiscal:</span>
                      <span class="d-inline-block ml-1">{{ o.address || '-' }}</span>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <p class="empty-table-text text-center" v-else>
            No se encontraron registros.
          </p>
        </table>

        <!-- Desktop -->
        <table class="custom-table w-100 position-relative d-none d-xl-table">
          <thead>
            <tr>
              <th>Lote</th>
              <th>Código de pago</th>
              <th>Tipo</th>
              <th>Metraje</th>
              <th>Porcentaje</th>
              <th>Dirección fiscal</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.number || '-' }}</td>
              <td class="text-center">{{ o.paymentCode ? o.paymentCode.code : '-' }}</td>
              <td class="text-center text-uppercase">
                {{ getType(o.type).label }}
              </td>
              <td class="text-center">{{ o.area || '-' }}</td>
              <td class="text-center">{{ o.percentage || '-' }}</td>
              <td class="text-center">{{ o.address || '-' }}</td>

              <td class="text-center d_option pr-md-4">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      :to="{
                        name: 'config-lots-details',
                        params: { id: o.id },
                      }"
                      text="Ver datos"
                    />
                    <DropdownItem
                      :to="{ name: 'config-lots-edit', params: { id: o.id } }"
                      text="Editar"
                    />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>

          <p class="empty-table-text text-center" v-else>
            No se encontraron registros.
          </p>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  Util,
  ConfirmationModalMixin,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ConfirmationModal,
  FilterMenu,
  FiltersIcon,
  CloseBorderRoundedIcon,
  RefreshTableIcon,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { LotService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    ConfirmationModal,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Tipo",
          key: "type",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.LotTypes.HOUSE,
            LocalConstants.LotTypes.LAND,
          ],
        },
      ],
    };
  },
  methods: {
    setFilters(options) {
      this.showFilters = false;
      this.filters = {...options};
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "id,asc",
          search: this.search,
          ...this.filters,
        };
        const response = await LotService.list(params);
        this.list = response.payload.content.map(item => {
          return {
            ...item,
            showMobileData: false
          }
        });
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getType(key) {
      return Util.searchValue(key, LocalConstants.LotTypes);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "delete") this.deleteItem();
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteItem() {
      try {
        await this.$store.dispatch("app/loading", true);
        await LotService.delete(this.selectedOption.id);
        Alert.success("Se ha eliminado la información correctamente")
        await this.loadData();
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>
